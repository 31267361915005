var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card, Table, TableBody, TableContainer } from '@mui/material';
import Scrollbar from '../../../../components/scrollbar';
import { TableHeadCustom, TableNoData, TablePaginationCustom, useTable, } from '../../../../components/table';
import LevelTableRow from './LevelTableRow';
import { IStatus } from '../../../../@types/status';
import LoadingTable from '../../../../components/loadingTable/LoadingTable';
import { deleteLevel, getAllLevels } from '../../../../redux/slices/levels/actions';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import usePermission from '../../../../hooks/usePermission';
var TABLE_HEAD = [
    {
        id: 'name',
        label: 'name',
        align: 'left',
    },
    {
        id: 'description',
        label: 'description',
        align: 'left',
    },
    { id: 'createdAt', label: 'created At', align: 'center' },
    { id: '__action', label: 'Actions', align: 'center' },
];
var LevelTable = function () {
    var _a;
    var _b = useTable({ defaultOrderBy: 'createdAt', defaultOrder: 'desc' }), dense = _b.dense, page = _b.page, order = _b.order, orderBy = _b.orderBy, rowsPerPage = _b.rowsPerPage, setPage = _b.setPage, 
    //
    onSelectRow = _b.onSelectRow, search = _b.search, setSearch = _b.setSearch, selected = _b.selected, onSort = _b.onSort, onChangeDense = _b.onChangeDense, onChangePage = _b.onChangePage, onChangeRowsPerPage = _b.onChangeRowsPerPage;
    useEffect(function () {
        dispatch(getAllLevels({
            page: page,
            limit: rowsPerPage,
        }));
    }, [page, rowsPerPage, dispatch]);
    var _c = useSelector(function (store) { return store.levels; }), levels = _c.levels, status = _c.status;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _d = useState(levels.docs), tableData = _d[0], setTableData = _d[1];
    var isNotFound = IStatus.SUCCEEDED && !(tableData === null || tableData === void 0 ? void 0 : tableData.length);
    useEffect(function () {
        setTableData(levels.docs);
    }, [levels]);
    // Permissions
    var _e = usePermission(), isSuperAdmin = _e.isSuperAdmin, hasPermissionViewLevel = _e.hasPermissionViewLevel, hasPermissionEditLevel = _e.hasPermissionEditLevel, hasPermissionDeleteLevel = _e.hasPermissionDeleteLevel;
    var isAllowedToViewLevel = isSuperAdmin || hasPermissionViewLevel;
    var isAllowedToEditLevel = isSuperAdmin || hasPermissionEditLevel;
    var isAllowedToDeleteLevel = isSuperAdmin || hasPermissionDeleteLevel;
    var canShowAction = isSuperAdmin || isAllowedToViewLevel || isAllowedToEditLevel || isAllowedToDeleteLevel;
    // Filter table head based on permissions
    TABLE_HEAD = canShowAction
        ? TABLE_HEAD
        : TABLE_HEAD.filter(function (elt) { return elt.label.toLocaleLowerCase().trim() !== 'action'; });
    var navigate = useNavigate();
    var handleDeleteRow = function (id) {
        dispatch(deleteLevel(id))
            .unwrap()
            .then(function (res) { var _a; return enqueueSnackbar("".concat((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message)); })
            .catch(function (err) { return enqueueSnackbar("".concat(err === null || err === void 0 ? void 0 : err.message), { variant: 'error' }); });
        // if (page > 0) {
        //   if (dataInPage.length < 2) {
        //     setPage(page - 1);
        //   }
        // }
    };
    var handleEditRow = function (row) {
        navigate("".concat(PATH_DASHBOARD.configuration.levels.edit(row._id)), { state: { level: row } });
    };
    var handleViewRow = function (row) {
        navigate("".concat(PATH_DASHBOARD.configuration.levels.view(row._id)), { state: { level: row } });
    };
    var denseHeight = dense ? 52 : 72;
    return (_jsxs(Card, { children: [_jsx(TableContainer, __assign({ sx: { position: 'relative', overflow: 'unset' } }, { children: _jsx(Scrollbar, __assign({ sx: { overflow: 'auto', maxHeight: 700 } }, { children: _jsxs(Table, __assign({ stickyHeader: true, "aria-label": "sticky table", size: dense ? 'small' : 'medium', sx: { minWidth: 800 } }, { children: [_jsx(TableHeadCustom, { order: order, orderBy: orderBy, headLabel: TABLE_HEAD, rowCount: 6, numSelected: selected === null || selected === void 0 ? void 0 : selected.length, onSort: onSort }), _jsxs(TableBody, { children: [status === IStatus.LOADING ? (_jsx(LoadingTable, { height: denseHeight, fields: TABLE_HEAD === null || TABLE_HEAD === void 0 ? void 0 : TABLE_HEAD.length, rowsPerPage: rowsPerPage })) : (_jsx(_Fragment, { children: tableData === null || tableData === void 0 ? void 0 : tableData.map(function (row) { return (_jsx(LevelTableRow, { row: row, selected: selected.includes(row._id), onSelectRow: function () { return onSelectRow(row._id || ''); }, onDeleteRow: function () {
                                                handleDeleteRow(row._id);
                                            }, onEditRow: function () {
                                                handleEditRow(row);
                                            }, onViewRow: function () {
                                                handleViewRow(row);
                                            } }, row._id)); }) })), status === IStatus.SUCCEEDED && (tableData === null || tableData === void 0 ? void 0 : tableData.length) === 0 && (_jsx(TableNoData, { isNotFound: isNotFound }))] })] })) })) })), _jsx(TablePaginationCustom, { count: ((_a = levels === null || levels === void 0 ? void 0 : levels.meta) === null || _a === void 0 ? void 0 : _a.totalDocs) || 0, page: page, rowsPerPage: rowsPerPage, onPageChange: onChangePage, onRowsPerPageChange: onChangeRowsPerPage, dense: dense, onChangeDense: onChangeDense })] }));
};
export default LevelTable;
